* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
body {
  width: 100%;
  height: 100%;
}
.inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(23, 23, 26, 0.08),
    0px 2px 8px 0px rgba(23, 23, 26, 0.05);
}
.card-img {
  /* margin: auto; */
  /* max-width: 90px; */
}
.card-text {
  max-width: 100%;
  display: flex;
  justify-content: center;
}

img.coconut-img {
  width: 100%;
}

.card-text-h6 {
  margin: 8px 0px 20px 0px;
  max-width: 150px;
  text-align: center;
  color: #17171A;
  font-size: 16px;
}
.price {
  color: #3d8705;
  /* line-height: 23px;
  font-size: 18px !important;
  font-weight: 700 !important; */
}
.img-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 8px;
  /* margin-bottom: 18px; */
}
/* cart list */

.table-btn {
  width: 100%;
}
.table-btn-div {
  border-top: 1px solid #e5e8e8;
  padding-top: 16px;
}
.my-table {
  margin-left: 15px;
}

.paymentCard {
  margin-left: 15px;
}

.maincardtwo .card {
  display: flex;
  min-width: 229px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(23, 23, 26, 0.08),
    0px 2px 8px 0px rgba(23, 23, 26, 0.05);
  padding: 8px 16px;
  margin: 0px 0px 1px;
}
.maincardtwo .price-h6 {
  margin: 16px 0px;
}
img.cross-close {
  width: 20px;
  height: auto;
}
.maincardtwo {
  gap: 0px;
}
.text-input {
  padding: 0px !important;
}
.check {
  padding: 24px 0px 16px 0px !important;
}

.check-next {
  padding: 16px 0px 0px 0px !important;
}

/* proceed */
.paymentMethods {
  display: flex;
  align-items: center;
  gap: 8px;
}

.paypalMethod {
  display: flex;
  justify-content: flex-start;
  padding-left: 25px;
}
.search-input.css-1nov498 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.css-1iz4iln {
  padding: 0px !important;
}

p.adyen-checkout-form-instruction {
  color: #000;
}

/* adyen checkout button */
button.adyen-checkout__button.adyen-checkout__button--pay {
  background-color: #3d8705;
}

/* adyen checkout button */
.adyen-checkout__loading-input__form.LoadingWrapper-module_loading-input__form__ffCKa {
  margin-top: 30px;
}

/* gpay */
button.gpay-card-info-container.black.long.en {
  height: 0px;
}

/*  */
span.MuiSwitch-root.MuiSwitch-sizeMedium.css-julti5-MuiSwitch-root {
  background-color: aliceblue;
  padding: 12px 40px;
  border-radius: 5px;
}
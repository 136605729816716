@font-face {
    font-family: 'nesxbd';
    src: url('./NespressoLucas-XtraBd.woff2') format('woff2'),
        url('./NespressoLucas-XtraBd.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'nesSemiBd';
    src: url('./NespressoLucas-SemiBdItalic.woff2') format('woff2'),
        url('./NespressoLucas-SemiBdItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'nesxbditalic';
    src: url('./NespressoLucas-XtraBdItalic.woff2') format('woff2'),
        url('./NespressoLucas-XtraBdItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'nesmdi';
    src: url('./NespressoLucas-MedItalic.woff2') format('woff2'),
        url('./NespressoLucas-MedItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'nessemiBd';
    src: url('./NespressoLucas-SemiBd.woff2') format('woff2'),
        url('./NespressoLucas-SemiBd.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'nesregular';
    src: url('./NespressoLucas-Regular.woff2') format('woff2'),
        url('./NespressoLucas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'nesMed';
    src: url('./NespressoLucas-Med.woff2') format('woff2'),
        url('./NespressoLucas-Med.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'neslightitalic';
    src: url('./NespressoLucas-LightItalic.woff2') format('woff2'),
        url('./NespressoLucas-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'neslight';
    src: url('./NespressoLucas-Light.woff2') format('woff2'),
        url('./NespressoLucas-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'nesBoldItalic';
    src: url('./NespressoLucas-BoldItalic.woff2') format('woff2'),
        url('./NespressoLucas-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'nesBold';
    src: url('./NespressoLucas-Bold.woff2') format('woff2'),
        url('./NespressoLucas-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'nesItalicNormal';
    src: url('./NespressoLucas-Italic.woff2') format('woff2'),
        url('./NespressoLucas-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


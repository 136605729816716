/* 01 iPad & Small Tablet Landscape (981 * 1200)  	
    ============================================================== */
@media (max-width: 1199px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.22;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
}

/* 02 General Media Query (iPhone, iPad, Small Tablet, 979 / 80 px)

=============================================================== */
@media (max-width: 991px) {
  /*** Global Classes CSS BOF ***/
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.22;
  }

  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 16px;
  }

  p {
    line-height: 1.7;
  }
}

/* 03 All Mobile Device (iPhone, Android, Window)(320 * 767)	
    ============================================================== */
@media (max-width: 767px) {
  /*** Global Classes CSS BOF ***/
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.22;
  }

  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 16px;
  }

  p {
    line-height: 1.7;
    text-align: center;
  }
  .list-unstyled {
    display: inline;
  }
  .list-unstyled.mob {
    display: flex;
  }

  .main-parent .my-class {
    display: flex;
    flex-direction: row;
  }
  .main-parent .my-class h6 {
    font-size: 16px;
  }
  .inner-content {
    flex-direction: row;
    padding: 5px 5px 0px 5px;
  }
  .img-icon {
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-left: 10px;
  }
  .card-text h6 {
    max-width: 200px;
    text-align: initial;
    line-height: 19px;
    margin: 10px 0px 0px 10px;
  }
  .card-text {
    justify-content: flex-start;
  }
  .card-img {
    margin: 0;
    max-width: 100%;
  }

  .coconut-img {
    width: 88px;
    height: 88px;
    object-fit: cover;
  }

  .tabimgfont {
    font-size: 14px;
    font-family: "nesMed";
    font-weight: 700;
  }

  .tabPadBtn {
    padding: 4px 0px !important;
  }
  .css-heg063-MuiTabs-flexContainer {
    gap: 16px !important;
  }
  .maincardtwo .card {
    flex-direction: row;
    padding: 5px;
  }
  .intensity {
    display: none;
  }
  .maincardtwo .price-h6 {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1px;
  }
  .maincardtwo .roma-h6 {
    /* font-size: 20px; */
    text-align: start;
    margin: 0px 0px;
  }
  .maincardtwo .price-h6 {
    margin: 0px 0px;
  }
  .maincardtwo .plus-img-div {
    text-align: -webkit-right;
  }
  .maincardtwo .close-icon {
    display: flex;
    justify-content: end;
  }

  .maincardtwo .close-icon {
    display: flex;
    justify-content: flex-start;
  }
  .cartList-img {
    width: 40px;
    height: 40px;
  }
  .my-table {
    padding: 16px;
    margin-top: 8px;
    margin-left: 0px;
  }

  .paymentCard {
    padding: 16px;
    margin-top: 10px;
    margin-left: 0px;
  }

  img.plus-img {
    margin-right: 16px !important;
  }
  .css-1iz4iln {
    padding: 0px !important;
  }
  .css-7l7wfg-MuiStack-root{
    width: 100%!important;
  }
}
